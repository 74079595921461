import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from "antd";
import { connect } from 'react-redux';
import Sidebar from "react-sidebar";
import NewAshreyaHome from "../pages/NewAshreyaHome";
import TopBarNew from "../components/TopBarNew";
import CustomFooter from "../components/Footer";
import NewCustomSideBar from "../components/NewCustomSideBar";
import BlogsDashboard from "../pages/NewAshreyaHome/components/blogsDashboard";
import { Blog1, Blog2, Blog3, Blog4, Blog5, Blog6, Blog7, Blog8, Blog9 } from "../pages/NewAshreyaHome/components/singleBlogData";
import TermsConditions from "../pages/termsConditions";
import PrivacyPolicy from "../pages/privacyPolicy";
import AsatRegister from "../pages/asatRegister";

const { Header, Content } = Layout;

const Root = ({ sideBar }) => {
  const [atBottom, setAtBottom] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.offsetHeight;

    setAtBottom(scrollTop + windowHeight >= documentHeight - 1);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ overflow: sideBar ? 'hidden' : 'unset', height: '100%', position: 'relative' }}>
      <Router>
        <Layout className="layout-body" style={{ minHeight: '100vh' }}>
          <Sidebar
            sidebar={<NewCustomSideBar />}
            open={sideBar}
            styles={{ sidebar: { background: "white", left: 0, right: 0 } }}
            pullRight={false}
          >
            <div></div>
          </Sidebar>
          <Layout className="layout-body" style={{ zIndex: '0' }}>
            <Header style={{ background: '#623695', height: 'auto' }}>
              <TopBarNew />
            </Header>
            <div style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              height: 'fit-content',
            }}>
              <CustomFooter />
            </div>
            <Content
              style={{
                paddingBottom: '540px',
                minHeight: 'calc(100vh - 540px)',
                overflow: 'auto',
                position: "relative",
                pointerEvents: atBottom ? "none" : "auto"
              }}
            >
              <div style={{ height: '100%', width: '100%' }}>
                <Switch>
                  <Route exact path="/" component={NewAshreyaHome} />
                  <Route exact path="/home" component={NewAshreyaHome} />
                  <Route exact path='/blogs' component={BlogsDashboard} />
                  <Route exact path='/blog/the-world-through-a-microscopic-lens' component={Blog7} />
                  <Route exact path='/blog/my-chemistry-chronical' component={Blog8} />
                  <Route exact path='/blog/empowering-students-for-sucess' component={Blog9} />
                  <Route exact path='/blog/glimpse-into-our-state-of-the-art-Facilities' component={Blog4} />
                  <Route exact path='/blog/ashreyas-educational-impact-across-assam' component={Blog5} />
                  <Route exact path='/blog/the-inspiring-journey-of-bitopan-kalita' component={Blog1} />
                  <Route exact path='/blog/tips-and-tricks-for-ashreya-academy-students' component={Blog3} />
                  <Route exact path='/blog/a-journey-from-challenges-to-success-in-jee-mains-2023' component={Blog2} />
                  <Route exact path='/blog/a-journey-through-physics-with-mayank-singh' component={Blog6} />
                  <Route exact path="/scholarship" component={NewAshreyaHome} />
                  <Route exact path="/about" component={NewAshreyaHome} />
                  <Route exact path="/terms-conditions" component={TermsConditions} />
                  <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                  <Route exact path="/asat-register" component={NewAshreyaHome} />
                </Switch>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Router>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { sideBar } = state.ashreya;
  return { sideBar };
};

export default connect(mapStateToProps)(Root);
