import React from 'react';
import "./styles.css"
import { Font17, Font19, Heading } from '../../../components/Typography';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';

const faqCardsData = [
    {
        title: "What courses does ashreya offer under its coaching and tutorial programs?",
        content: "ashreya offers one-year online and offline coaching for NEET and JEE aspirants, as well as online tutorials for Class X students. These programs include live and recorded lectures, personalized study materials, and regular assessments."
    },
    {
        title: "How does ashreya support students who prefer learning in regional languages?",
        content: "ashreya has started offering courses in Assamese and will soon expand to include all regional languages available in NEET, ensuring that language preferences do not hinder student success."
    },
    {
        title: "Can students access recorded classes and study materials?",
        content: "Yes, students can access class recordings, lecture notes, and practice papers in ashreya’s digital library, available 24/7 for self-paced learning."
    },
    {
        title: "What subjects are covered in the Class X tutorials program?",
        content: "The Class X tutorials program covers English, Science, Mathematics, Advanced Mathematics, and Computer Science aligned with both ASSEB and CBSE curricula."
    },
    {
        title: "What is the eligibility criteria for enrolling in the NEET and JEE coaching programs?",
        content: "The NEET and JEE coaching programs are open to students who are currently studying or have passed Class XII."
    },
    {
        title: "What makes ashreya’s coaching and tutorial programs different from others?",
        content: "ashreya offers a unique blend of live and recorded lectures, paired with personalized study materials. With instruction available in both English and Assamese, the programs are designed to be accessible and effective for a diverse range of students."
    },
    {
        title: "How are assessments conducted at ashreya?",
        content: "Assessments at ashreya include chapter tests, combined tests, and full-length tests, all conducted within the app, providing comprehensive practice aligned with the syllabus."
    },
    {
        title: "How do I attend online classes with ashreya?",
        content: "Online classes can be attended directly through the ashreya app, which offers both live and recorded sessions, providing flexibility and convenience to students."
    },
    {
        title: "Where can I download the ashreya app?",
        content: (
            <>
                The ashreya app can be downloaded from the <a href="https://play.google.com/store/apps/details?id=com.ashreya.aulas&hl=en-IN" target="_blank" rel="noopener noreferrer">Google Play Store</a> for Android devices and the <a href="https://apps.apple.com/us/app/aulas/id1564724183?platform=iphone" target="_blank" rel="noopener noreferrer">Apple App Store</a> for iOS devices. Simply scan the QR code available on our website or search for 'ashreya' in your app store.
            </>
        )
    },
    {
        title: "How do I register or log in to the ashreya app?",
        content: "To register, download the app, enter your details, and follow the simple steps. If you already have an account, simply log in with your registered email or phone number and password."
    },
    {
        title: "What is your refund policy?",
        content: "After completion of registration, there is no refund."
    },
    {
        title: "Can I get a trial class before enrolling?",
        content: "Yes, ashreya provides a single day trial session after registration in the ashreya app."
    },
    {
        title: "Do you provide study materials?",
        content: "Yes, study materials are provided as part of the coaching and tutorial programs, including live and recorded lectures, lecture notes, and practice papers."
    },
    {
        title: "How do I enroll in a course?",
        content: (
            <>
                To enroll, visit <a href="https://ashreya.aulas.in" target="_blank" rel="noopener noreferrer">ashreya.aulas.in</a> or use the ashreya app and follow the instructions for registration.
            </>
        )
    }
];


const CollapsibleCard = ({ title, content, isOpen, onClick }) => {
    return (
        <div className="collapsible-card" onClick={onClick}>
            <div className="collapsible-card-header" >
                <Font19 fontWeight={isOpen ? 700 : 400} >{title}</Font19>
                {isOpen ? <MinusOutlined style={{ color: "#4B17CD", fontSize: 18, zIndex: 100 }} className='hide-faq-icon' /> : <PlusOutlined style={{ color: "#4B17CD", fontSize: 18, zIndex: 100 }} className='hide-faq-icon' />}
            </div>
            {isOpen && <div className='collapsible-card-content'><Font17 lineHeight={30}>{content}</Font17></div>}
        </div>
    );
};

function FAQ() {
    const [openIndex, setOpenIndex] = useState(null);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='new-faq-container responsive-padding'>
            <img src={require("../../../assets/NewAshreyaHome/questionsBro.svg").default} alt="questionsBro" loading='lazy' style={{ zIndex: 10, }} className='questionBro-size' />
            <div className='new-faq-card'>
                <Heading bold>Frequently asked questions</Heading>
                <div className="collapsible-cards">
                    {faqCardsData.map((card, index) => (
                        <CollapsibleCard
                            key={index}
                            title={card.title}
                            content={card.content}
                            isOpen={openIndex === index}
                            onClick={() => handleClick(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQ