import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Banner from './components/banner';
import ExploreCourses from './components/exploreCourses';
import AshreyaApp from './components/ashreyaApp';
import MoreAboutAshreya from './components/moreAboutAshreya';
import Mentorship from './components/mentorship';
import ShiningStars from './components/shiningStars';
import FAQ from './components/faq';
import Blogs from './components/blogs';
import FloatingBtn from './components/floatingBtn';
import Testimonials from './components/testimonials';
import '../NewAshreyaHome/components/styles.css';

function NewAshreyaHome() {
    const [showFloatingBtn, setShowFloatingBtn] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 800);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 800);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
            const shouldShowBtn = window.scrollY < (isMobile ? 4300 : 4650);
            setShowFloatingBtn(shouldShowBtn);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobile]);

    const sections = [
        { Component: Banner, key: 'banner' },
        { Component: ExploreCourses, key: 'exploreCourses' },
        { Component: AshreyaApp, key: 'ashreyaApp' },
        // { Component: MoreAboutAshreya, key: 'moreAboutAshreya' },
        { Component: Mentorship, key: 'mentorship' },
        { Component: ShiningStars, key: 'shiningStars' },
        { Component: FAQ, key: 'faq' },
        { Component: Blogs, key: 'blogs' },
        { Component: Testimonials, key: 'testimonials' }
    ];

    return (
        <div className="container">
            <div className="content">
                {sections.map(({ Component, key }) => (
                    <AnimatedSection key={key}>
                        <Component />
                    </AnimatedSection>
                ))}
            </div>
            {showFloatingBtn && <FloatingBtn />}
        </div>
    );
}

function AnimatedSection({ children }) {
    const ref = useRef(null);
    const controls = useAnimation();
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && mounted.current) {
                    controls.start('visible');
                } else if (mounted.current) {
                    controls.start('hidden');
                }
            },
            { threshold: 0.1 }
        );

        const currentRef = ref.current;
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            mounted.current = false;
        };
    }, [controls]);

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
            }}
        >
            {children}
        </motion.div>
    );
}

export default NewAshreyaHome;
