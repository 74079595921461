import React, { useEffect, useState, useRef } from 'react';
import './styles.css';
import { Font17, Font21, Heading } from '../../../components/Typography';
import neetIcon from "../../../assets/NewAshreyaHome/neetIcon.svg";
import jeeIcon from "../../../assets/NewAshreyaHome/jeeIcon.svg";
import tenthIcon from "../../../assets/NewAshreyaHome/tenthIcon.svg";

const exploreCoursesArray = [
    {
        icon: neetIcon,
        title: "NEET UG Courses",
        desc: "Prepare for NEET with our experienced faculties.",
        bgc: "#F1F7EB",
        animationColor: "rgba(201, 226, 182, 30%)"
    },
    {
        icon: jeeIcon,
        title: "JEE Courses",
        desc: "Prepare for JEE with our experienced faculties.",
        bgc: "#EDF3FB",
        animationColor: "rgba(184, 207, 240, 30%)"
    },
    {
        icon: tenthIcon,
        title: "10th & 12th Boards",
        desc: "A premium tuition for students of 10th standard (CBSE/ASSEB).",
        bgc: "#FFECF4",
        animationColor: "rgba(246, 197, 218, 30%)"
    },
];

const ExploreCoursesCard = ({ item, index }) => {
    const [isInView, setIsInView] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsInView(true);
                    setTimeout(() => setIsInView(false), 3000);
                }
            },
            { threshold: 0.1 }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, []);

    const glareStyle = {
        backgroundImage: `linear-gradient(to right, ${item.animationColor} 24%, transparent 26% 53%, ${item.animationColor} 54%)`,
        animation: isInView ? 'fadeInRight 3s forwards' : 'none'
    };

    return (
        <div
            className='explore-courses-card'
            ref={cardRef}
            style={{ backgroundColor: item.bgc, cursor: "pointer" }}
            onClick={() => {
                if (index === 0) {
                    window.open('https://ashreya.aulas.in/all-popular-courses?id=4');
                } else if (index === 1) {
                    window.open('https://ashreya.aulas.in/all-popular-courses?id=6');
                } else if (index === 2) {
                    window.open('https://ashreya.aulas.in?id=pyq');
                }
            }}
            key={index}
        >
            <div className='card-glare' style={glareStyle}></div>
            <img src={item.icon} loading="lazy" alt="courses-icon" className="explore-courses-icon" style={{ zIndex: 100 }} />
            <div className="m-l-20 c-jc" style={{ zIndex: 100 }}>
                <Font21 bold>{item?.title}</Font21>
                <Font17 fontWeight={400} lineHeight={32} margin="5px 0px 0px 0px" noJustify>
                    {item?.desc}
                </Font17>
            </div>
        </div>
    );
};

function ExploreCourses() {
    return (
        <div className="explore-courses-container">
            <div className="c-c-c responsive-padding">
                <div className='explore-courses-heading-margin'>
                    <Heading bold>Explore our courses</Heading>
                </div>
                <center className='explore-courses-sub-heading-width m-t-20'>
                    <Font17 lineHeight={30}>Discover tailored programs designed to empower your educational journey. Whether you're preparing for board exams or excelling in NEET/JEE.</Font17>
                </center>
                <div className="explore-courses-cards-parent m-t-50">
                    {exploreCoursesArray.map((item, index) => (
                        <ExploreCoursesCard item={item} index={index} key={index} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ExploreCourses;
