import { SET_SIDEBAR, SET_SUB_DOMAIN_HOVERED, SET_BLOG_DETAIL } from "../action/ashreya"

const initialState = {
    sideBar: false,
    subDomainHovered: false,
    blogDetail: 0
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR:
            return {
                ...state,
                sideBar: action.val
            }
        case SET_SUB_DOMAIN_HOVERED:
            return {
                ...state,
                subDomainHovered: action.val
            }
        case SET_BLOG_DETAIL:
            return {
                ...state,
                blogDetail: action.val
            }
        default:
            return state;
    }
};

export default reducer;
