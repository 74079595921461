import React, { useRef, useState, useEffect } from 'react';
import './styles.css';
import { Font17, Font23, Heading } from '../../../components/Typography';
import { motion, useAnimation } from 'framer-motion';

const mentorshipData = [
    {
        title: "Learn from the best",
        desc: "Expert guidance from teachers with up to 10+ years of experience providing valuable mantras for success."
    },
    {
        title: "Personalized guidance",
        desc: "We ensure personalized support to fuel the academic and professional growth of every student."
    },
    {
        title: "Regional language guidance",
        desc: (
            <>
                Learn in <a href="https://ashreya.aulas.in/all-popular-courses?id=4" target="_blank" rel="noopener noreferrer">Assamese</a> language from our expert mentors, making learning more accessible and effective.
            </>
        )
    }
];

const MentorshipCard = ({ item }) => {
    return (
        <div className='r-flex-start m-t-30'>
            <img src={require("../../../assets/NewAshreyaHome/mentors-arrow.svg").default} loading='lazy' alt="arrow-image" className='arraow-image-size' />
            <div className='m-l-20 c-jc'>
                <Font23 bold>{item?.title}</Font23>
                <Font17 lineHeight={30} margin={"10px 0px 0px 0px"}>{item?.desc}</Font17>
            </div>
        </div>
    );
}

function Mentorship() {
    const [inView, setInView] = useState(false);
    const mentorshipContentRef = useRef(null);
    const controls = useAnimation();

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        setInView(true);
                    } else {
                        setInView(false);
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (mentorshipContentRef.current) {
            observer.observe(mentorshipContentRef.current);
        }

        return () => {
            if (mentorshipContentRef.current) {
                observer.unobserve(mentorshipContentRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (inView) {
            controls?.start({
                scale: [0.5, 1],
                opacity: [0.5, 1],
                transition: { duration: 1, ease: "easeInOut", delay: 0.5 } 
            });
        } else {
            controls?.start({
                scale: 1,
                opacity: 1,
                transition: { duration: 1, ease: "easeInOut", delay: 0.5 } 
            });
        }
    }, [inView, controls]);

    return (
        <div className='mentorship-container responsive-padding'>
            <div className='mentorship-heading-margin show-mob-mentorship-heading'>
                <Heading bold lineHeight={32}>Our experienced mentorship</Heading>
            </div>
            <div className='mentorship-content-child1'>
                <div className='mentorship-heading-margin show-web-mentorship-heading'>
                    <Heading bold lineHeight={32}>Our experienced mentorship</Heading>
                </div>
                <div className=''>
                    {mentorshipData.map((item, index) => (
                        <MentorshipCard key={index} item={item} />
                    ))}
                </div>
            </div>
            <div className='mentorship-content-child2' ref={mentorshipContentRef}>
                <div className="image-wrapper zoom">
                    <motion.img
                        src={require("../../../assets/NewAshreyaHome/zoom-mentors-img.svg").default}
                        alt="zoom-mentors-image"
                        className="hover-image"
                        loading='lazy'
                        animate={controls}
                    />
                </div>
            </div>
        </div>
    );
}

export default Mentorship;
