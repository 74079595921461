import React from "react";
import { connect } from "react-redux";
import { Col, Hidden, Row, Visible } from "react-grid-system";
import { ArrowLeftOutlined } from '@ant-design/icons';
import examimg from '../../../assets/Blog/exams.png'
import timeImg from '../../../assets/Blog/time.png'
import buildImg from '../../../assets/Blog/build.png'
import solveImg from '../../../assets/Blog/solve.png'
import practiceImg from '../../../assets/Blog/practice.png'
import revisionImg from '../../../assets/Blog/revision.png'
import identifyImg from '../../../assets/Blog/identify.png'
import stayMotivatedImg from '../../../assets/Blog/stayMotivated.png'
import seekImg from '../../../assets/Blog/seekHelp.png'
import mayank from "../../../assets/Blog/blog4Image.svg"
import microLense from "../../../assets/Blog/blog1Image.svg";
import dkd from '../../../assets/Blog/blog2Image.svg'
import shrvan from '../../../assets/Blog/blog3Image.svg'
import insideAshreya from '../../../assets/Blog/blog5Image.svg';
import empower from '../../../assets/Blog/blog6Image.svg'
import bitopan from "../../../assets/Blog/blog7Image.svg"
import amlan from "../../../assets/Blog/blog8Image.svg"
import breakingIIt from "../../../assets/Blog/blog9Image.svg"
import { setBlogDetail } from "../../../action/ashreya";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './styles.css'
import { Font17, Font19 } from "../../../components/Typography";
import { useEffect } from "react";

export const Blog1 = ({ history }) => {
    let p1 = `Bitopan Kalita, a determined and ambitious young individual hailing from Guwahati, has embarked on an incredible journey towards achieving his dreams in the field of medicine. With unwavering dedication, resilience, and the support of his loved ones and the Vectors Academy, Bitopan has overcome various challenges and emerged as a shining example of success. This is the inspiring success story of Bitopan Kalita, a future medical pioneer dedicated to revolutionizing healthcare in the northeast region.`
    let p2 = `Born and raised in Guwahati, Bitopan Kalita grew up in a modest family environment. His mother, Biju Rani Kalita, is a homemaker, and his father, Jitendra Kalita, is a retired teacher. From an early age, Bitopan displayed a keen interest in the sciences and a strong desire to make a positive impact on society. Recognizing his passion and potential, his parents provided him with unwavering support and encouragement to pursue his dreams.`
    let p3 = `Academic life took on a whole new meaning for Bitopan when he joined Vectors Academy. The institution not only equipped him with comprehensive academic knowledge but also instilled in him a unique approach to studying and excelling in examinations. The friendly and supportive teachers at Vectors Academy played a pivotal role in Bitopan's journey, helping him clear doubts in subjects like physics, chemistry, and biology. Their guidance and mentorship proved invaluable as Bitopan consistently demonstrated remarkable academic performance.`
    let p4 = `In 2022, Bitopan Kalita participated in the highly competitive NEET UG examination, a gateway to realizing his dream of becoming a doctor. Through sheer determination and perseverance, Bitopan emerged triumphant, scoring an impressive 655 out of 720. His remarkable achievement not only brought pride to his family but also solidified his position as a frontrunner in the medical field.    `
    let p5 = `With his outstanding NEET UG performance, Bitopan Kalita secured admission to the esteemed AIIMS Guwahati, a testament to his academic prowess and potential. The journey at AIIMS Guwahati proved to be transformative for Bitopan, providing him with unparalleled exposure to cutting-edge medical knowledge, state-of-the-art facilities, and a diverse community of aspiring medical professionals.    `
    let p6 = `Furthermore, Bitopan's dedication and commitment to excellence did not go unnoticed. His exceptional academic track record and passion for medicine earned him the prestigious ashreya Scholarship, covering his entire tuition fees for the five-year MBBS program. This recognition not only alleviated the financial burden on Bitopan and his family but also served as a testament to his remarkable abilities and potential.    `
    let p7 = `Bitopan Kalita, driven by a deep sense of responsibility towards the people of the northeast region, aspires to become a great doctor and play a vital role in revolutionizing the medical field. He envisions a future where healthcare reaches every corner of the region, alleviating the struggles faced by the underprivileged and bridging the healthcare gap.    `
    let p8 = `Aside from his academic pursuits, Bitopan actively engages in hobbies such as playing cricket, Kho-kho, and painting. These activities serve as a means of rejuvenation and self-expression, allowing him to maintain a well-rounded and balanced lifestyle amidst the demands of medical education.    `
    let p9 = `Bitopan Kalita's aspirations extend beyond his personal achievements. He seeks to dedicate himself to the well-being of the people, aiming to reduce prevalent issues such as unemployment and poverty in the northeast region. With his newfound medical knowledge and the determination to make a difference, Bitopan endeavors to collaborate with local organizations and community initiatives to address these socio-economic challenges effectively.    `
    let p10 = `Bitopan Kalita's success story is one of perseverance, determination, and the unwavering support of his loved ones and educational institution. From his humble beginnings in Guwahati to securing admission at AIIMS Guwahati and being recognized as the first recipient of the prestigious ashreya Scholarship, Bitopan's journey serves as an inspiration to countless aspiring medical professionals. With his sights set on revolutionizing healthcare in the northeast region and his unwavering dedication, Bitopan Kalita is poised to make a lasting impact, contributing significantly to the welfare of the nation and beyond.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={bitopan} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">From Humble Beginnings to Medical Excellence: The Inspiring Journey of Bitopan Kalita</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>Early Life and Academic Excellence</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p3}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>The NEET UG 2022 Journey</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>Pursuing Medical Education at AIIMS Guwahati</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p5}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p6}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>The Northeast Revolution: A Vision for a Better Future</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p7}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p8}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>Contributing to the Welfare of the Nation</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p9}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p10}</Font17>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const Blog2 = ({ history }) => {
    let p1 = `In the realm of academic achievements, few accomplishments rival the recognition and honor of securing a remarkable percentile in the Joint Entrance Examination (JEE) Mains. Amlan Jyoti Sarma, a 19-year-old from Nalbari, has emerged as a shining example of perseverance and dedication. Despite facing financial obstacles, Amlan's determination and the support of Vectors Academy have propelled him to an exceptional achievement, scoring an impressive 99.87 percentile in the JEE Mains 2023 examination. This success story encapsulates Amlan's journey, his aspirations, and the impactful role of his mentors and society.    `
    let p2 = `IIT JEE (Indian Institutes of Technology Joint Entrance Examination) is a highly significant and prestigious examination in India. It serves as a gateway for students aspiring to pursue engineering and technological education at the esteemed Indian Institutes of Technology (IITs), National Institutes of Technology (NITs), and several other renowned engineering institutions across the country. The significance of IIT JEE in India can be understood from various perspectives, such as academic excellence, career opportunities, societal recognition, and the overall impact on the nation's development.    `
    let p3 = `Firstly, IIT JEE is important due to the exceptional academic standards maintained by the IITs. These institutions have consistently ranked among the top engineering schools globally, renowned for their rigorous curriculum, distinguished faculty, and cutting-edge research facilities. The process of preparing for IIT JEE demands extensive study, problem-solving skills, and critical thinking abilities, which nurtures a strong foundation in science and mathematics. The intense competition and comprehensive syllabus of the exam push students to enhance their academic capabilities, thereby fostering a culture of academic excellence in the country.    `
    let p4 = `Secondly, qualifying the IIT JEE opens up a plethora of career opportunities for aspirants. The IITs have a remarkable track record of producing successful engineers, entrepreneurs, and leaders in various sectors. The institutes boast strong industry collaborations, which facilitate internships, research projects, and campus placements with leading companies both in India and abroad. The IIT alumni network is vast and influential, providing excellent career prospects and mentorship opportunities. The success achieved by IIT alumni in diverse fields such as technology, finance, entrepreneurship, and public administration further enhances the reputation of the exam and the institutions.    `
    let p5 = `Moreover, clearing the IIT JEE carries significant societal recognition in India. The exam is highly competitive, with a large number of aspirants vying for limited seats. Qualifying the exam is seen as a remarkable achievement, not only for the students but also for their families and communities. The prestige associated with being an IITian extends beyond academic circles, creating a sense of pride and honor. It serves as a symbol of meritocracy and inspires younger generations to strive for excellence in education.    `
    let p6 = `From a national perspective, the IITs play a vital role in the development of India's technological landscape and economic growth. These institutions have been at the forefront of innovation, research, and development, addressing critical challenges and driving technological advancements. The IITs produce skilled engineers who contribute to the workforce, fostering innovation, entrepreneurship, and industrial development. Their research outputs and collaborations with industries have a direct impact on the country's technological capabilities, competitiveness, and overall progress.    `
    let p7 = `IlT JEE holds immense importance in India due to its role in promoting academic excellence, opening up rewarding career opportunities, garnering societal recognition, and contributing to the nation's development. The exam acts as a catalyst for individual growth, shaping talented minds into successful professionals and leaders. It is a testament to India's commitment to nurturing scientific and technological talent, paving the way for a brighter future in engineering and innovation.`
    let p8 = `Amlan's pursuit of quality coaching for the JEE Mains examination was met with financial constraints that initially left him disheartened. However, in August 2022, he stumbled upon Vectors Academy, which would soon become the turning point in his life. Under the guidance of Amit Singh sir, Amalendu Dutta sir, and Debasis Deka sir, Amlan found not just knowledgeable educators but also friendly and caring mentors. Their support helped him overcome his initial struggles and transformed him from a beginner to a confident aspirant.    `
    let p9 = `Amlan, like many other aspiring students, nurtures the dream of studying in prestigious institutions like the Indian Institutes of Technology (IITs) and National Institutes of Technology (NITs). He envisions himself pursuing various research fields within the IIT system, shaping his academic career with a strong foundation. With his exceptional performance in the JEE Mains 2023 examination, Amlan is now setting his sights on the next milestone: the JEE Advanced 2023.    `
    let p10 = `Recognizing the crucial role society plays in a student's growth, Amlan is driven by a sense of duty to give back to his community. Coming from a backward area where the IITs were scarcely known and often mistaken for ITIs, he aims to contribute to the field of education. Amlan believes that every student should strive to uplift society through their knowledge and expertise, and he aspires to become an educator who can make a positive impact on the lives of others.`
    let p11 = `Beyond his academic pursuits, Amlan has nurtured a deep love for the arts. He finds solace and creative expression in composing poems, showcasing his ability to appreciate the beauty of language and emotions. Additionally, playing the flute has become another significant hobby in his life, allowing him to find balance and harmony amidst the rigors of exam preparation.`
    let p12 = `Amlan's success story in the JEE Mains 2023 examination is a testament to his resilience, dedication, and the unwavering support he received from Vectors Academy. It is an achievement that reflects his remarkable journey from a place of financial struggles to becoming a beacon of inspiration for countless others. Amlan expresses his heartfelt gratitude to the entire Vectors family for their unwavering guidance and support, which played a pivotal role in his success. With his sights now set on the JEE Advanced 2023 examination, Amlan continues to pursue his dreams while remaining committed to contributing meaningfully to society and nurturing his passions.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={amlan} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">Amlan Jyoti Sarma: A Journey from Challenges to Success in JEE Mains 2023</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p3}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p5}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p6}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p7}</Font17>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>A Journey of Challenges and Discovering Vectors Academy</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p8}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>Dreams and Ambitions</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p9}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>A Commitment to Society</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p10}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>Pursuing Passions and Finding Balance</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p11}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>A Journey of Gratitude and Acknowledgment</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p12}</Font17>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const Blog3 = ({ history }) => {
    const TipsCard = ({ order, image, title, description }) => {
        return (
            <>
                <Visible sm md lg xl xxl>
                    <div className='m-t-50' style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 30 }}>
                        {order == "first" ?
                            <>
                                <img style={{ height: 220, width: 400, borderRadius: 8 }} src={image} alt="iamge" loading='lazy' />
                                <div className='m-l-40' style={{ display: "flex", flexDirection: "column" }}>
                                    <Font19 bold color="#623695">{title}</Font19>
                                    <Font17 color="#191919" lineHeight={24} margin={"30px 0px 0px 0px"}>{description}</Font17>
                                </div>
                            </>
                            :
                            <>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Font19 bold color="#623695">{title}</Font19>
                                    <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{description}</Font17>
                                </div>
                                <div className='m-l-40'><img style={{ height: 220, width: 400 }} src={image} alt="iamge" loading='lazy' /></div>
                            </>
                        }
                    </div>
                </Visible>
                <Hidden lg xl xxl xxxl>
                    <div className='m-t-20' style={{ width: "100%" }} >
                        <Font19 bold color="#623695">{title}</Font19>
                        <img style={{ marginTop: 20, width: "100%", borderRadius: 8, marginBottom: 10 }} src={image} alt="iamge" loading='lazy' />
                        <Font17 color="#191919" lineHeight={24} >{description}</Font17>
                    </div>
                </Hidden>
            </>
        )
    }
    let p1 = `Clearing the IIT JEE and NEET exams is a dream for many students in India. The exams are highly competitive and require immense dedication and hard work. IIT JEE and NEET are the two most coveted competitive exams in India for engineering and medical aspirants respectively. Every year,
    lakhs of students across the country appear for these exams, but only a few are able to crack them. In this blog, we will discuss some tips and tricks that will help students of Vectors Academy to crack these exams. Vectors Academy is one of the premier institutes in India that provides coaching for IIT JEE and NEET exams. The focus remains to discuss some tips and tricks that will help Vectors Academy students to crack these exams.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={breakingIIt} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">Breaking the IIT JEE and NEET Code: Tips and Tricks for Vectors Academy Students</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <TipsCard
                        order={"first"}
                        image={examimg}
                        title={"Understand the exam pattern"}
                        description={"The first and foremost thing that Vectors Academy students need to do is understand the exam pattern of IIT JEE and NEET exams. Both these exams have different sections and each section carries a different weightage. Students must be aware of the types of questions asked in the exams and the time allotted for each section."}
                    />
                    <TipsCard
                        order={"second"}
                        image={timeImg}
                        title={"Time Management"}
                        description={"Time management is crucial in clearing IIT JEE and NEET exams. Vectors Academy students must develop a time-management strategy that works best for them. They should allocate sufficient time for each subject and section, practice solving problems within a stipulated time, and take mock tests regularly to evaluate their performance."}
                    />
                    <TipsCard
                        order={"first"}
                        image={buildImg}
                        title={"Build a strong foundation"}
                        description={"The first and fore most thing that every student should do is to build a strong foundation. This means that you should have a clear understanding of the basics of all the subjects that are included in the exams. For instance, in physics, you should be thorough with concepts like kinematics, laws of motion, work, energy and power, etc. Similarly, in chemistry, you should be well-versed with concepts like mole concept, chemical bonding, periodic table, etc. Once you have a strong foundation, it will be easier for you to understand and solve complex problems. "}
                    />
                    <TipsCard
                        order={"second"}
                        image={solveImg}
                        title={"Solve Previous Year Papers"}
                        description={"Solving previous year papers is an excellent way to prepare for IIT JEE and NEET exams. Vectors Academy students should solve as many previous year papers as possible to get an idea of the type of questions asked in the exams. They should also analyze their performance and identify their strengths and weaknesses."}
                    />
                    <TipsCard
                        order={"first"}
                        image={practiceImg}
                        title={"Practice, Practice, and Practice"}
                        description={"Practice is the key to success in any competitive exam. It is essential to solve as many practice papers, sample papers, and previous years' papers as possible. This will not only help you in getting an idea of the exam pattern and the type of questions asked but also improve your speed and accuracy. You should also try to solve the questions within the time limit as it will help you in managing your time during the actual exam."}
                    />
                    <TipsCard
                        order={"second"}
                        image={revisionImg}
                        title={"Revision"}
                        description={"Revision is an essential part of exam preparation. Vectors Academy students must revise the concepts, formulas, and problem-solving techniques regularly. They should also create short notes and flashcards to revise quickly and effectively.                    "}
                    />
                    <TipsCard
                        order={"first"}
                        image={identifyImg}
                        title={"Identify Your Weak Areas"}
                        description={"It is essential to identify your weak areas and work on them. You should solve more problems from the topics that you find difficult and try to clear your doubts. You can also take help from your teachers, friends, or online resources to understand the concepts better.                    "}
                    />
                    <TipsCard
                        order={"second"}
                        image={examimg}
                        title={"Take Mock Tests"}
                        description={"Mock tests are a great way to evaluate your preparation level. You should take as many mock tests as possible and try to analyze your mistakes. You should also try to solve the questions that you were not able to solve during the mock test. This will help you in improving your performance in the actual exam."}
                    />
                    <TipsCard
                        order={"first"}
                        image={stayMotivatedImg}
                        title={"Stay motivated"}
                        description={"Staying motivated is crucial in clearing IIT JEE and NEET exams. Vectors Academy students must set realistic goals and work towards achieving them. They should also reward themselves for every milestone achieved, which will keep them motivated and focused.                    "}
                    />
                    <TipsCard
                        order={"second"}
                        image={seekImg}
                        title={"Seek help"}
                        description={"Vectors Academy students must seek help whenever they face difficulty in understanding any concept or solving a problem. They can approach their teachers, peers, or seniors for help. They can also attend doubt clearing sessions organized by the institute.                    "}
                    />
                    <Font17 color="#191919" lineHeight={24} margin={"50px 0px 0px 0px"}>{"Cracking the IIT JEE and NEET exams requires a lot of hard work, dedication, and focus. Vectors Academy students can follow the above tips and tricks to prepare effectively for these exams. They must also stay calm and composed during the exams and attempt the questions with a clear mind. With the right approach and mindset, Vectors Academy students can achieve their dream of clearing IIT JEE and NEET exams."}</Font17>
                </div>
            </div>
        </div>
    )
}

export const Blog4 = ({ history }) => {
    let p1 = `Welcome to ashreya, where excellence meets innovation, and learning is redefined. Nestled in the heart of Guwahati, Assam, ashreya stands tall as a beacon of educational brilliance, offering a comprehensive range of facilities designed to nurture the minds of tomorrow's leaders. In this blog post, we invite you to take a virtual tour of our state-of-the-art facilities, each meticulously crafted to provide an unparalleled educational experience.`
    let p2 = `At ashreya, we believe in learning from the best. That's why our faculty members are not just educators but also accomplished professionals who have themselves excelled in their respective fields, many as IITians. With their wealth of knowledge and expertise, they serve as mentors, guiding students on their academic journey while inspiring them to reach new heights of achievement. Whether it's clarifying complex concepts or providing career guidance, our IITian faculties are dedicated to unlocking the full potential of every student.`
    let p3 = `Step into our classrooms, and you'll discover a dynamic learning environment where innovation thrives. Equipped with the latest technology and ergonomic furniture, our classrooms are designed to facilitate interactive learning experiences. From multimedia presentations to collaborative projects, students engage with course material in ways that stimulate their curiosity and foster critical thinking. With ample natural light and comfortable seating, our classrooms provide the perfect setting for academic exploration and intellectual growth. `
    let p4 = `Science comes to life in our cutting-edge laboratories, where theory meets practice, and experimentation leads to discovery. From physics and chemistry to biology and computer science, our labs are equipped with state-of-the-art equipment and resources that enable hands-on learning experiences. Under the guidance of experienced instructors, students conduct experiments, analyse data, and draw conclusions, gaining valuable insights that deepen their understanding of scientific principles. Whether it's dissecting a frog or programming a robot, our labs offer endless opportunities for exploration and discovery. `
    let p5 = `At ashreya, we understand the importance of practical learning experiences in complementing classroom instruction. That's why we offer a wide range of practical activities designed to reinforce theoretical concepts and enhance student learning. From field trips and workshops to internships and industry collaborations, our practical activities expose students to real-world applications of their academic knowledge. Whether it's conducting field research in ecology or participating in a coding hackathon, these experiences empower students to apply what they've learned in meaningful ways, preparing them for success in their future endeavours.`
    let p6 = `For students who come from afar to pursue their education at ashreya, we offer comfortable and secure hostel facilities that serve as a home away from home. Our hostels are equipped with modern amenities and supervised by dedicated staff who ensure the well-being and safety of our residents. With spacious rooms, nutritious meals, and recreational facilities, our hostels provide a supportive environment where students can focus on their studies while forming lasting friendships and memories. Whether it's studying late into the night or unwinding after a long day, our hostels offer the perfect blend of comfort and convenience. `
    let p7 = `At ashreya, we are committed to ensuring that every student has the opportunity to pursue their dreams, regardless of their financial circumstances. That's why we proudly offer the ashreya Scholarship Program, a testament to our belief in meritocracy and equal access to education. .`
    let p8 = `Through this program, we recognize and reward the academic excellence of our high-achieving students by providing scholarships that cover their tuition fees for prestigious institutions like IIT and NEET coaching. This initiative not only alleviates the financial burden on deserving students but also encourages them to strive for academic excellence with the assurance of continued support from ashreya. `
    let p9 = `Furthermore, the ashreya Scholarship Program extends beyond our institute to reach students in need of financial assistance who may not be part of our institution. Through outreach programs and partnerships with schools and community organizations, we identify talented students who demonstrate exceptional potential but lack the financial means to pursue higher education. These students are provided with scholarships that cover their tuition fees, enabling them to access quality education and unlock opportunities for a brighter future.`
    let p10 = `By investing in the education of promising young minds, the ashreya Scholarship Program embodies our commitment to empowering dreams and transforming lives. Through the generosity of our donors and the dedication of our team, we aim to create a more equitable and inclusive society where every individual has the chance to thrive and succeed.`
    let p11 = `ashreya's state-of-the-art facilities are more than just amenities—they are the foundation upon which our commitment to excellence is built. From our IITian faculties to our cutting-edge labs, every aspect of our facilities is designed to inspire, empower, and support our students on their educational journey. So come, experience the ashreya difference, where learning knows no bounds, and the future is limitless.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={insideAshreya} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">Inside ashreya: A Glimpse into Our State-of-the-Art Facilities</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>1. IITian Faculties: Nurturing Excellence</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>2. State-of-the-Art Classrooms: Where Learning Comes Alive</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p3}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>3. Cutting-Edge Labs: Laboratories of Discovery</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>4. Practical Activities: Bridging Theory and Practice</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p5}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>5. Hostels and Accommodation: A Home Away from Home</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p6}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>6. ashreya Scholarship Program: Empowering Dreams</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p7}</Font17>
                    </div>
                    <Font17 color="#191919" lineHeight={24}>{p8}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p9}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p10}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p11}</Font17>
                </div>
            </div>
        </div>
    )
};

export const Blog5 = ({ history }) => {
    let p1 = `In the heart of Assam, where verdant landscapes meet bustling communities, ashreya Educational Institution stands as a beacon of empowerment and progress. Beyond conventional education, ashreya is on a mission to redefine the educational landscape of Assam, reaching out to communities far and wide, from the bustling streets of Guwahati to the serene corners of rural villages. Through a plethora of initiatives and programs, ashreya is shaping the educational destiny of Assam, one student at a time. Join us on this transformative journey as we explore the myriad ways in which ashreya is making a lasting impact on educational development across the state.`
    let p2 = `Nestled in the heart of Guwahati, ashreya serves as a hub of educational excellence, catering to students from diverse backgrounds. However, our commitment to educational growth extends far beyond the city limits, encompassing the rural landscapes and remote villages of Assam. Through a network of outreach programs and initiatives, ashreya is bridging the gap between urban and rural education, ensuring that every child, regardless of their location, has access to quality education.`
    let p3 = `Our outreach efforts include partnering with local schools and community organizations to provide educational resources, mentorship, and support to students in rural areas. From conducting workshops on academic excellence to offering scholarships and financial assistance, ashreya is dedicated to nurturing the academic aspirations of students in every corner of Assam. By empowering these young minds with the tools they need to succeed, we are paving the way for a brighter future for the entire state.`
    let p4 = `At ashreya, we believe that education is the greatest equalizer—the key to unlocking a world of opportunities for every individual. That's why we have established the ashreya Scholarship Program, a testament to our commitment to providing access to quality education for all. Through this program, we offer scholarships and financial aid to deserving students, covering tuition fees for prestigious institutions such as IIT and NEET coaching centers. `
    let p5 = `But our scholarship program goes beyond the boundaries of our institution, extending its reach to students across Assam who need financial assistance. By providing support to talented individuals from underprivileged backgrounds, we are breaking down barriers to education and creating pathways to success for the next generation of leaders and innovators in our state.`
    let p6 = `In the halls of ashreya, education is not just about memorization and rote learning—it's about igniting a passion for learning, fostering creativity, and inspiring innovation. Our faculty members, who are experts in their fields, bring a wealth of real-world experience and practical wisdom to the classroom, creating an environment where students can thrive and explore their interests.`
    let p7 = `Through interactive lectures, hands-on projects, and experiential learning activities, our faculty members encourage students to think critically, problem-solve creatively, and pursue their passions. By promoting a fresh perspective on education—one that values curiosity, experimentation, and lifelong learning—we are preparing students to navigate the complexities of the modern world and make meaningful contributions to society.`
    let p8 = `In a society where gender stereotypes often limit the educational and career opportunities available to women, ashreya is committed to breaking down barriers and empowering female students to pursue their dreams. With a growing number of successful female students excelling in fields traditionally dominated by men, we are challenging outdated norms and fostering a culture of gender equality and empowerment within our institution.`
    let p9 = `Through mentorship programs, leadership initiatives, and empowerment workshops, we provide female students with the support and resources they need to succeed academically and professionally. By celebrating their achievements and amplifying their voices, we are inspiring future generations of girls to dream big, aim high, and shatter glass ceilings in every sphere of life.`
    let p10 = `Beyond the realm of academics, ashreya is deeply committed to making a positive impact on the communities we serve. Through a variety of social impact initiatives and community engagement efforts, we strive to create a more vibrant, inclusive, and compassionate society where everyone has the opportunity to thrive.`
    let p11 = `From organizing cultural festivals and community events that celebrate the rich diversity of Assam to hosting seminars and workshops on topics ranging from career guidance to mental health awareness, we provide a platform for dialogue, collaboration, and collective action. By fostering connections, building bridges, and nurturing a sense of belonging, we are creating stronger, more resilient communities where everyone feels valued, supported, and empowered to reach their full potential.`
    let p12 = `ashreya's educational impact across Assam is a testament to the power of education to transform lives, uplift communities, and create a brighter future for all. Through our unwavering commitment to holistic development, community empowerment, and social impact, we are not just shaping the educational destiny of Assam but also inspiring a new generation of leaders, innovators, and change-makers to dream big, aim high, and make their mark on the world. `
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={empower} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">Empowering Communities: ashreya's Educational Impact Across Assam</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>1. Empowering Educational Growth: Bridging Gaps Across Assam</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p3}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>2. Scholarship Program: Opening Doors to Opportunity</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p5}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>3. A Fresh Perspective on Education: Inspiring Innovation and Creativity</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p6}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p7}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>4. Breaking Gender Stereotypes: Empowering Female Leaders</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p8}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p9}</Font17>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Font19 bold margin={"10px 0px 20px 0px"}>5. Engaging in Social Impact: Enriching Communities Through Collaboration</Font19>
                        <Font17 color="#191919" lineHeight={24}>{p10}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p11}</Font17>
                        <Font17 color="#191919" lineHeight={24} margin={"20px 0px 0px 0px"}>{p12}</Font17>
                    </div>
                </div>
            </div>
        </div>
    )
};

export const Blog6 = ({ history }) => {
    let p1 = `Hey there, fellow seekers of knowledge! I'm Mayank Singh, your guide on this exhilarating voyage through the boundless realms of physics. Join me as we embark on an odyssey to unravel the mysteries of the cosmos, from the tiniest subatomic particles to the vast expanse of the universe itself.`
    let p2 = `Let's start at the beginning – Banaras, the ancient city steeped in history and tradition. It was here that my journey into the world of physics began, amidst the bustling streets and serene ghats of this cultural hub. As a wide-eyed undergrad at Banaras Hindu University, I was captivated by the wonders of the natural world and the laws that governed it.`
    let p3 = `Fuelled by curiosity and a thirst for knowledge, I pursued my passion for physics at IIT Guwahati, where I delved deeper into the intricacies of the universe. It was during this time that I had the honour of representing my state as a top scorer in the Physics Olympiad, a testament to years of dedication and hard work.`
    let p4 = `But my quest for understanding didn't stop there. Driven by a desire to inspire and educate others, I ventured into the world of teaching. From the bustling metropolis of Delhi NCR to the historical city of Agra, I imparted my knowledge and passion for physics to eager young minds at Aakash Institute.`
    let p5 = `And now, I find myself here at ashreya, a beacon of light for those who seek to explore the wonders of the universe. At ashreya, we don't just teach physics – we ignite a spark, a curiosity that burns bright within every student. Our mission is simple yet profound: to foster a love for learning and to empower our students to reach their full potential.And now, I find myself here at ashreya, a beacon of light for those who seek to explore the wonders of the universe. At ashreya, we don't just teach physics – we ignite a spark, a curiosity that burns bright within every student. Our mission is simple yet profound: to foster a love for learning and to empower our students to reach their full potential.`
    let p6 = `But what sets ashreya apart? It's our unwavering commitment to excellence and innovation in education. We believe that learning should be a dynamic and interactive experience, where students are encouraged to question, explore, and discover. Through hands-on experiments, thought-provoking discussions, and real-world applications, we bring the principles of physics to life in ways that captivate the imagination.`
    let p7 = `But perhaps most importantly, we recognize that education is not just about acquiring knowledge – it's about instilling a passion for lifelong learning. Whether our students go on to become scientists, engineers, or entrepreneurs, we want them to carry with them the spirit of inquiry and discovery that defines the essence of physics.`
    let p8 = `So, to all the budding scientists and explorers out there, I invite you to join us on this incredible journey. Together, let's unlock the secrets of the universe and pave the way for a brighter, more enlightened future. With curiosity as our compass and knowledge as our guide, the possibilities are limitless. Let's dive in and explore the wonders of the cosmos – one equation at a time.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={mayank} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">Unveiling the Universe: A Journey Through Physics with Mayank Singh</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p3}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p5}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p6}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p7}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p8}</Font17>
                </div>
            </div>
        </div>
    )
};

export const Blog7 = ({ history }) => {
    let p1 = `How did it all start? A question that has lingered in my thoughts from a time I barely remember. How did life begin? What is life? Questions such as these have captivated my thoughts ever since I understood life. The fact that all of us are born, all of us live, and in the end, all of us, sadly but inevitably, die. But what if we didn’t die? How different would the world be? What separates the living from the non-living? Where is the fine line? These questions were pertinent to satiate my curiosity. Delving into the world of living beings meant exploring greater grounds of physics, astronomy, chemistry, and even mathematics! Life encompasses all aspects of science, for without all the wheels coming together, the vehicle of life would cease to exist. The odds of everything coming together to form life are so slim that even to this day, out of all the planets, satellites, and stars found in the observable universe, the only planet that seems to have life is our mother Earth! Why is that so? I often wonder. Here I was, seeking answers to these enthralling questions, which propelled me to an endearing journey with life sciences, visualizing life through a microscopic lens!`
    let p2 = `After finishing my Higher Secondary Examinations, I was admitted to a prestigious college in Guwahati called B. Borooah College, where I studied Zoology as my honours subject. I was fortunate enough to study at the prestigious Cotton University in Guwahati for my master's (in Zoology) and received my degree in 2015. I had several career options to choose from after my master's, but I couldn’t contemplate a career away from biology. This is why I chose teaching, a profession wherein I could pass on knowledge to the younger generation and at the same time keep my learning curve going. My interests also led me to a career in research, pursuing a PhD, studying the behaviour of lymphoma cells.`
    let p3 = `My association with ashreya started in 2022 when I was called upon to take classes in botany. The association was initially planned to be part-time, but as fate would have it, I was offered a full-time role in this institute. I was drawn to the environment and work culture of ashreya. What struck me most was the care every student at ashreya received. For a teacher, grooming a student for the world ahead not only includes studies but also the holistic growth of the child. ashreya believes in nurturing students to not only strive for academic excellence but also to motivate them to push the envelope in public speaking, writing, and life skills. Now, as the principal of this academy, it is my responsibility to ensure that every student at ashreya benefits. Our vision is to ensure that every student can conform to the ideas and pace of the modern world while establishing their identity. We have always put quality over quantity, and we intend to keep our virtue in the foreseeable future.
   As I continue to explore the intricacies of life through the lens of science and education, I am reminded that every question holds the promise of discovery, and every student at ashreya embodies the potential to shape our ever-evolving world.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={microLense} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">THE WORLD THROUGH A MICROSCOPIC LENS</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p3}</Font17>
                </div>
            </div>
        </div>
    )
};

export const Blog8 = ({ history }) => {
    let p1 = `Hey there, curious minds! Welcome to my corner of the universe, where atoms collide, reactions unfold, and every question is a gateway to discovery. I'm Debashish Kumar Deka, and I'm thrilled to take you on a journey through the enchanting realm of chemistry. Let me paint you a picture of my academic abode – ashreya Institute. Why ashreya, you ask? Well, it's simple – small batches, big dreams. Picture a classroom where every student's voice echoes, every question sparks a conversation, and every concept finds its home in eager minds. That's the magic of ashreya, where I have the privilege of serving as a chemistry faculty member.`
    let p2 = `Now, what sets ashreya apart? It's the intimacy, the personal touch. Here, we believe in quality over quantity, depth over breadth. With smaller class sizes, I can dive deep into the intricacies of chemistry, ensuring that each student not only understands but truly grasps the wonders of the subject. It's a joy to witness those 'aha' moments when everything clicks into place. But teaching isn't just about delivering lectures and grading papers. It's about fostering growth, nurturing potential, and celebrating success. That's why ashreya goes the extra mile, offering regular exams, part tests, and full-scale assessments to keep students on their toes. And let's not forget the one-on-one counselling sessions – those heart-to-heart conversations where doubts are dispelled, fears are conquered, and dreams are nurtured.`
    let p3 = `In this digital age, support is just a click away. Whether it's clarifying doubts over email or guiding students through online resources, I'm always here to lend a helping hand. After all, learning doesn't stop when the classroom doors close – it's a lifelong journey. But what drives me? It's simple – the joy of witnessing growth. There's nothing more rewarding than seeing my students flourish, whether it's mastering a challenging concept or acing a difficult exam. Their success is my fuel, driving me to constantly innovate and inspire.`
    let p4 = `Beyond the classroom, I find inspiration in the world around me. Traveling is my passion, my escape, my source of rejuvenation. Exploring new cultures, meeting new people, and experiencing new landscapes – it's all fuel for the soul. And let's not forget about sports – the ultimate stress reliever. Whether it's a game of soccer with friends or a solo hike in the mountains, physical activity keeps me balanced and focused.`
    let p5 = `So, why chemistry? For me, it's more than just a subject – it's a way of understanding the world. From the tiniest atom to the grandest chemical reaction, chemistry is the language of nature, and I'm just a humble translator. And with me as your guide, this journey promises to be nothing short of extraordinary.`
    let p6 = `My journey with chemistry is a testament to the power of passion, dedication, and a thirst for knowledge. Through my work at ashreya Institute, I hope to ignite the same spark of curiosity and wonder in every one of my students. So, fellow travellers, are you ready to embark on this adventure with me? The wonders of chemistry await!`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={dkd} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">My Chemistry Chronicle: A Personal Expedition with Debashish Kumar Deka</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p3}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p5}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p6}</Font17>
                </div>
            </div>
        </div>
    )
};

export const Blog9 = ({ history }) => {
    let p1 = `In the vast landscape of education, where dreams are nurtured and futures are forged, few individuals possess the ability to inspire, guide, and transform lives like Shrawan Kumar. Armed with a BTech degree from the prestigious IIT Guwahati and enriched by a remarkable 15 years of experience, Shrawan Kumar stands tall as a paragon of excellence in the realm of mathematics mentoring. His journey is not merely one of personal achievement but a saga of dedication, passion, and unwavering commitment to empowering students for success.`
    let p2 = `At the heart of Shrawan Kumar's philosophy lies the belief that education is not merely about imparting knowledge but about igniting a flame of curiosity, fostering critical thinking, and instilling a lifelong love for learning. With this ethos as his guiding light, Shrawan embarked on his journey as a mentor, determined to make a meaningful difference in the lives of aspiring students preparing for the daunting IIT JEE and NEET exams.From the outset, Shrawan understood that success in these competitive exams transcends mere academic proficiency; it requires a holistic approach that encompasses conceptual clarity, problem-solving skills, and unwavering self-belief. Armed with this understanding, he set out to redefine the paradigms of mathematics education, blending traditional pedagogy with innovative teaching methodologies to create a learning experience that is both enriching and transformative.`
    let p3 = `Central to Shrawan's approach is the notion of nurturing talent. He firmly believes that every student possesses inherent potential waiting to be unlocked, and it is the role of a mentor to provide the guidance and support necessary for this potential to blossom. With this in mind, Shrawan goes beyond the confines of textbooks, striving to create an environment where students feel empowered to explore, question, and discover. 	More than anything else, it is Shrawan's unwavering commitment to his students' success that sets him apart. He understands the challenges and pressures that accompany the journey of preparing for competitive exams, and he stands as a pillar of strength and support for his students every step of the way. Whether it's through late-night doubt-clearing sessions, motivational pep talks, or personalized mentoring, Shrawan leaves no stone unturned in ensuring that his students have the best possible chance of success.`
    let p4 = `In his 15 years of mentoring, Shrawan has witnessed countless success stories – stories of students who defied odds, surpassed expectations, and achieved their dreams. But amidst these tales of triumph, there are also stories of setbacks, failures, and moments of self-doubt. And it is in these moments that Shrawan's true mettle shines through. For him, failure is not an endpoint but a stepping stone towards greater success. He teaches his students to embrace failure, to learn from it, and to emerge stronger and more resilient than ever before.Shrawan's impact extends far beyond the confines of the classroom. Through his tireless efforts, he has cultivated a community of learners – a community bound together by a shared passion for knowledge and a relentless pursuit of excellence. It is this sense of camaraderie and solidarity that sets ashreya apart as more than just an educational institution; it is a family, a sanctuary where students can find not only academic guidance but also emotional support and encouragement.`
    let p5 = `For Shrawan, ashreya isn't just a name; it's a promise – a promise to provide students with not just an education but a transformative experience that will shape their futures. It's a promise to create a supportive and nurturing environment where every student can thrive and reach their full potential. It's a promise to uphold the values of integrity, perseverance, and compassion in everything that they do. To the students, Shrawan sir's message is clear: Believe in yourself, work hard, and never underestimate your capabilities. Success isn't a destination; it's a journey, and with the right guidance and determination, every obstacle can be overcome. He urges them to dream big, to set ambitious goals, and to pursue their passions with unwavering dedication and resolve.`
    let p6 = `In the world of competitive exams, where the stakes are high and the pressure immense, Shrawan and ashreya stand as beacons of hope and guidance. Together, they pave the path to success, one student at a time, inspiring generations to reach for the stars and realize their fullest potential. And as long as there are dreams to be chased and horizons to be conquered, Shrawan Kumar will continue to be a guiding light, illuminating the way forward for generations to come.`
    return (
        <div className="container">
            <div className="content">
                <div className="responsive-padding blogs-margin" style={{ position: 'relative' }}>
                    <img src={shrvan} loading='lazy' alt='BlogImg8' style={{ width: "100%", objectFit: 'cover' }} />
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 back-btn" style={{ position: 'absolute', top: 20 }}>
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <div className='r-c-c blogs-purple-box'>
                        <Font19 bold color="#ffffff">Empowering Students for Success: The Journey of Shrawan Kumar</Font19>
                    </div>
                </div>
                <div className="responsive-padding blogs-paragraph">
                    <Font17 color="#191919" lineHeight={24}>{p1}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p2}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p3}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p4}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p5}</Font17>
                    <Font17 color="#191919" lineHeight={24}>{p6}</Font17>
                </div>
            </div>
        </div>
    )
};


const SingleBlogData = ({ blogDetail }) => {
    const history = useHistory()
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div>
            {blogDetail == 1 ?
                <Blog1 history={history} />
                : blogDetail == 2 ?
                    <Blog2 history={history} />
                    : blogDetail == 3 ?
                        <Blog3 history={history} />
                        : blogDetail == 4 ?
                            <Blog4 history={history} />
                            : blogDetail == 5 ?
                                <Blog5 history={history} />
                                : blogDetail == 6 ?
                                    <Blog6 history={history} /> :
                                    blogDetail == 7 ?
                                        <Blog7 history={history} /> :
                                        blogDetail == 8 ?
                                            <Blog8 history={history} /> :
                                            blogDetail == 9 ?
                                                <Blog9 history={history} /> :
                                                null}
        </div>
    )
};

const mapStateToProps = (state) => {
    const { blogDetail } = state.ashreya
    return { blogDetail }
};
const mapDispatchToProps = (dispatch) => ({
    setBlogDetail: val => dispatch(setBlogDetail(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleBlogData)