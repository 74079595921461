import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import './styles.css'
import { Font15, Font17, Font21, Heading } from '../../../components/Typography';
import { motion } from 'framer-motion';
import { Carousel } from 'antd';

const testimonials = [
    {
        name: 'Khushi Chetry',
        period: 'ashreya coaching, NEET (2025)',
        testimonial: 'Thank you ashreya for delivering the quality education. In just four months, I’ve overcome weaknesses and achieved remarkable results. The experienced faculty, always available 24/7, has boosted my confidence. Joining ashreya was the best decision for my future.',
        image: require("../../../assets/NewAshreyaHome/student1.svg").default
    },
    {
        name: 'Debajit Pegu',
        period: 'ashreya coaching, NEET (2025)',
        testimonial: 'ashreya has been a game-changer for me! The teachers make complex concepts easy to understand, and the digital infrastructure allows us to study from anywhere. I feel confident as I prepare for competitive exams, and the personalized guidance here is helping me excel in my board exams too.',
        image: require("../../../assets/NewAshreyaHome/student2.svg").default
    },
    {
        name: 'Satya Giri',
        period: 'ashreya coaching, NEET (2025)',
        testimonial: 'My heartfelt thanks to ashreya. The teachers guide us like sunlight through trees, helping us develop our concepts beautifully. This institute is where we build strong foundations, and with ashreya\'s support, I’m confident in achieving my dreams and best results.',
        image: require("../../../assets/NewAshreyaHome/student3.svg").default
    },
    {
        name: 'Jubli Deka',
        period: 'ashreya coaching, NEET (2025)',
        testimonial: 'The faculty at ashreya is very approachable, making learning much easier. The small class sizes allow for individual attention, boosting my confidence in problem-solving. I\'m truly grateful to ashreya for bringing me a step closer to my dreams.',
        image: require("../../../assets/NewAshreyaHome/student4.svg").default
    },
    {
        name: 'Nur Alom Sk',
        period: 'ashreya coaching, JEE (2025)',
        testimonial: 'I am incredibly grateful to be part of this institute. It’s one of the best coaching centers for IIT-JEE and NEET. The faculty is always available and ready to help whenever needed. The environment is welcoming, and the teachers are friendly, making it an ideal place for students.',
        image: require("../../../assets/NewAshreyaHome/student5.svg").default
    },
    {
        name: 'Pranjal Chetia',
        period: 'ashreya coaching, NEET (2025)',
        testimonial: 'The Small class size and individual attention at ashreya made all the difference in my learning experience. The faculty are truly exceptional, Their guidance and support helped me a lot, If you are an Aspirants who is looking for a coaching you must visit once.',
        image: require("../../../assets/NewAshreyaHome/student6.svg").default
    },
];


const Testimonials = () => {
    const sliderRef = useRef(null);

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
        ref: sliderRef,
        speed: 1000
    };

    const TestimonialCard = ({ item }) => {
        const [hideBorder, setHideBorder] = useState(false);

        useEffect(() => {
            const timer = setTimeout(() => {
                setHideBorder(true);
            }, 600);
            return () => clearTimeout(timer);
        }, []);

        return (
            <center className='m-t-20 m-b-20 testimonial-card-margin'>
                <div className={`testimonial-card ${hideBorder ? 'border-hidden' : ''}`} style={{ '--border-color': "#B297CE" }}>
                    <div className="inner">
                        <div style={{ textAlign: "center" }}>
                            <Font17 fontWeight={400} lineHeight={30}>{item?.testimonial}</Font17>
                        </div>
                        <div className='r-ac m-t-20'>
                            <img src={item.image} loading='lazy' alt="testimonial-card-image" style={{ width: 60, height: 60, borderRadius: 50 }} />
                            <div className='m-l-20 c-jc' style={{ textAlign: "left" }}>
                                <Font21 bold>{item?.name}</Font21>
                                <Font15 fontWeight={400} lineHeight={32} margin={"5px 0px 0px 0px"} color='#AEAEAE'>{item?.period}</Font15>
                            </div>
                        </div>
                    </div>
                </div>
            </center>
        );
    };

    const [activeIndex, setActiveIndex] = useState(0);
    const carouselRef = useRef(null);

    const handleAfterChange = (current) => {
        setActiveIndex(current);
    };

    const handleImageClick = (index) => {
        if (carouselRef.current) {
            carouselRef.current.goTo(index);
        }
        setActiveIndex(index);
    };

    return (
        <div className='testimonials-container responsive-padding'>
            <Heading bold>Student testimonials</Heading>
            <center className='m-t-20 testimonials-sub-heading-width'>
                <Font17 lineHeight={30} margin={"20px 0px 0px 0px"}>
                    Discover real stories from our students, sharing their journeys and successes. See how ashreya has transformed their educational experience!
                </Font17>
            </center>
            <div className="carousel-container">
                <Carousel
                    autoplay
                    dots={false}
                    autoplaySpeed={5000}
                    afterChange={handleAfterChange}
                    className='testimonial-slider-height'
                    ref={carouselRef}
                >
                    {/* <Slider {...settings} ref={sliderRef} className='testimonial-slider-height'> */}
                    {testimonials.map((student, index) => (
                        <div key={index} className="testimonial-slide">
                            <TestimonialCard item={student} />
                        </div>
                    ))}
                    {/* </Slider> */}
                </Carousel>
                <div className="student-images">
                    {testimonials.map((student, index) => {
                        const [isVisible, setIsVisible] = useState(false);
                        const imageRef = useRef(null);

                        useEffect(() => {
                            const observer = new IntersectionObserver(
                                ([entry]) => {
                                    setIsVisible(entry.isIntersecting);
                                },
                                { threshold: 0.1 }
                            );

                            if (imageRef.current) {
                                observer.observe(imageRef.current);
                            }

                            return () => {
                                if (imageRef.current) {
                                    observer.unobserve(imageRef.current);
                                }
                            };
                        }, []);
                        return (
                            <motion.div
                                key={index}
                                ref={imageRef}
                                className={`student-image ${index === activeIndex ? 'highlighted' : ''} ${index === 2 || index === 5 ? 'small' : index === 3 || index === 4 ? "medium" : ""}`}
                                onClick={() => handleImageClick(index)}
                                animate={{ scale: isVisible ? 1 : 0.5 }}
                                transition={{ duration: 0.3 }}
                            >
                                <img src={student.image} alt={student.name} />
                            </motion.div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
