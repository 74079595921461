import React, { useEffect } from 'react'
import { Font15, Font17, Heading } from '../../../components/Typography'
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { setBlogDetail } from '../../../action/ashreya';
import blog1Image from "../../../assets/Blog/blog1Image.svg";
import blog2Image from "../../../assets/Blog/blog2Image.svg";
import blog3Image from "../../../assets/Blog/blog3Image.svg";
import blog4Image from "../../../assets/Blog/blog4Image.svg";
import blog5Image from "../../../assets/Blog/blog5Image.svg";
import blog6Image from "../../../assets/Blog/blog6Image.svg";
import blog7Image from "../../../assets/Blog/blog7Image.svg";
import blog8Image from "../../../assets/Blog/blog8Image.svg";
import blog9Image from "../../../assets/Blog/blog9Image.svg";
import "./styles.css"
import { ArrowLeftOutlined } from '@ant-design/icons';

const displayedBlogs = (setBlogDetail, history) => [
    {
        image: blog4Image,
        title: 'Unveiling the Universe: A Journey Through Physics with Mayank Singh',
        description: 'Hey there, fellow seekers of knowledge! I m Mayank Singh, your guide on this exhilarating voyage through... ',
        value: () => { setBlogDetail(6); history.push('/blog/a-journey-through-physics-with-mayank-singh') }
    },
    {
        image: blog1Image,
        title: 'THE WORLD THROUGH A MICROSCOPIC LENS',
        description: 'How did it all start? A question that has lingered in my thoughts from a time I barely remember. How did life...',
        value: () => { setBlogDetail(7); history.push('/blog/the-world-through-a-microscopic-lens') }
    },
    {
        image: blog2Image,
        title: 'My Chemistry Chronicle: A Personal Expedition with Debashish Kumar Deka',
        description: 'Hey there, curious minds! Welcome to my corner of the universe, where atoms collide, reactions unfold, and...',
        value: () => { setBlogDetail(8); history.push('/blog/my-chemistry-chronical') }
    },
    {
        image: blog3Image,
        title: 'Empowering Students for Success: The Journey of Shrawan Kumar',
        description: 'In the vast landscape of education, where dreams are nurtured and futures are forged, few individuals...',
        value: () => { setBlogDetail(9); history.push('blog/empowering-students-for-sucess') }
    },
    {
        image: blog5Image,
        title: 'Inside ashreya: A Glimpse into Our State-of-the-Art Facilities',
        description: 'Welcome to ashreya, where excellence meets innovation, and learning is redefined. Nestled in the heart...',
        value: () => { setBlogDetail(4); history.push('/blog/glimpse-into-our-state-of-the-art-Facilities') }
    },
    {
        image: blog6Image,
        title: 'Empowering Communities: ashreya`s Educational Impact Across Assam',
        description: 'In the heart of Assam, where verdant landscapes meet bustling communities, ashreya Educational Institution...',
        value: () => { setBlogDetail(5); history.push('/blog/ashreyas-educational-impact-across-assam') }
    },
    {
        image: blog7Image,
        title: `From Humble Beginnings to Medical Excellence:  The Inspiring Journey of Bitopan Kalita`,
        description: 'Bitopan Kalita, a determined and ambitious young individual hailing from Guwahati, has embarked on an incre...',
        value: () => { setBlogDetail(1); history.push('/blog/the-inspiring-journey-of-bitopan-kalita') }
    },
    {
        image: blog8Image,
        title: 'Amlan Jyoti Sarma: A Journey from Challenges to Success in JEE Mains 2023',
        description: 'In the realm of academic achievements, few accomplishments rival the recognition and honor of ...',
        value: () => { setBlogDetail(2); history.push('/blog/a-journey-from-challenges-to-success-in-jee-mains-2023') }
    },
    {
        image: blog9Image,
        title: 'Breaking the IIT JEE and NEET Code: Tips and Tricks for ashreya Academy Students',
        description: 'Clearing the IIT JEE and NEET exams is a dream for many students in India. The exams are highly competitive...',
        value: () => { setBlogDetail(3); history.push('/blog/tips-and-tricks-for-ashreya-academy-students') }
    },
]

const BlogsCard = ({ item, onClick }) => {
    return (
        <div className='blog-card m-b-20' style={{ width: "100%", display: "flex", flexDirection: "column", cursor: "pointer" }} onClick={() => onClick()}>
            <img src={item.image} loading='lazy' alt="blogs-image" className='m-b-10' style={{ width: "100%" }} />
            <Font15 lineHeight={26} color={"#636363"} className="truncate-2-lines blog-descriptionText">{item?.description}</Font15>
        </div>
    );
}

function BlogsDashboard({ setBlogDetail }) {
    const history = useHistory()
    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, []);
    return (
        <div className="container">
            <div className="content">
                <div className='blogs-container responsive-padding m-t-100'>
                    <div onClick={() => { history.goBack() }} className="r-c-c radius-10 cursor-pointer font-600 blogs-back-btn">
                        <ArrowLeftOutlined style={{ fontSize: 20, color: "white" }} />
                    </div>
                    <Heading bold>Blogs</Heading>
                    <center className='m-t-20 blogs-sub-heading-width'>
                        <Font17 lineHeight={30}>Explore our blogs for a comprehensive collection of articles, expert opinions, and trend analysis.</Font17>
                    </center>
                    <div className='r-sb blogs-fit'>
                        {displayedBlogs(setBlogDetail, history)?.map((item, index) => (
                            <BlogsCard item={item} key={index} onClick={item?.value} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
};
const mapDispatchToProps = (dispatch) => ({
    setBlogDetail: (val) => dispatch(setBlogDetail(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BlogsDashboard)